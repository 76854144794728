import axios, { type AxiosInstance } from 'axios'
import { type AxiosRequestConfig, type InternalAxiosRequestConfig } from 'axios'
import { useSpinnerStore } from '@/stores/spinner'
import { DatadogService } from './datadog.service'
import AxiosError from '@/util/errors/AxiosError'
interface CustomAxiosConfig extends AxiosRequestConfig {
  showLoader?: boolean
}

interface CustomRequestConfig extends InternalAxiosRequestConfig {
  showLoader?: boolean
}

export const API_ENDPOINTS = {
  FOLDERS: '/folders',
  ASSESSMENTS: '/assessments'
}
class AxiosService {
  private axiosInstance
  constructor() {
    this.axiosInstance = axios.create({
      baseURL: import.meta.env.VITE_AM_API_URL,
      showLoader: true
    } as CustomAxiosConfig)
    this.initializeInterceptors(this.axiosInstance)
  }
  private initializeInterceptors(instance: AxiosInstance) {
    instance.interceptors.request.use(
      (config: CustomRequestConfig) => {
        const spinnerStore = useSpinnerStore()

        if (config.showLoader) {
          spinnerStore.pending()
        }
        return config
      },
      (error) => {
        const spinnerStore = useSpinnerStore()
        if (error.config.showLoader) {
          spinnerStore.done()
        }

        return Promise.reject(error)
      }
    )

    instance.interceptors.response.use(
      (response) => {
        const spinnerStore = useSpinnerStore()
        const { config }: { config: CustomRequestConfig } = response
        if (config.showLoader) {
          spinnerStore.done()
        }

        return response
      },
      (error) => {
        const spinnerStore = useSpinnerStore()
        const response = error.response
        const config = response?.config

        if (config?.showLoader) {
          spinnerStore.done()
        }

        const statusCode = response?.status
        const errorMsg = response?.data?.error || response?.data
        const stackTrace = error?.stack
        const body = response?.config?.data || {}
        const statusText = response?.statusText
        const httpMethod = error?.config?.method
        const endpoint = response?.config?.url
        const axiosError = new AxiosError(
          statusCode,
          errorMsg,
          stackTrace,
          body,
          statusText,
          httpMethod,
          endpoint
        )
        // Extract API error details to send to datadog
        const additionalInfo = {
          statusCode,
          errorMsg,
          stackTrace,
          body,
          statusText,
          httpMethod,
          endpoint
        }

        // Log the error to DataDog
        DatadogService.addErrorWithStack(axiosError, additionalInfo)

        return Promise.reject(error)
      }
    )
  }
  public getAxiosInstance(showLoaderValue: boolean = true) {
    const instance = axios.create({
      baseURL: import.meta.env.VITE_AM_API_URL,
      showLoader: showLoaderValue
    } as CustomAxiosConfig)

    this.initializeInterceptors(instance)
    return instance
  }
}

export const axiosInstance = new AxiosService().getAxiosInstance()
export const customAxiosInstance = (showLoader: boolean = true) =>
  new AxiosService().getAxiosInstance(showLoader)
